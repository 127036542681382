import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  if (apiClient.defaults.headers.common.Authorization !== axios.defaults.headers.common.Authorization) {
    apiClient.defaults.headers.common.Authorization = axios.defaults.headers.common.Authorization;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
}, error => Promise.reject(error));

export default {
  getSalaries(query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/salaries', {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getOrderSalaries(orderId) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/orders/${orderId}/salaries`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getEmployeeSalaries(employeeId, query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/employees/${employeeId}/salaries`, {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getExpertSalaries(query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/salaries/expert', {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  getOrdersSettlement(contractorId, query) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/contractors/${contractorId}/order-income-report`, {
        params: query,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  updateAsPaid(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch('/salaries/update-as-paid-many', body)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
    return result;
  },
  update(id, body) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/salaries/${id}/update`, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  add(body) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/salaries', body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
  delete(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/salaries/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    return result;
  },
};
