<template>
  <div>
    <ul class="flex border-b">
      <li class="mr-1" :class="{'-mb-px': paid === 0 || paid === '0'}">
        <a href="#" @click.prevent="changeTab(0)" class="tab" :class="{'active': paid === 0 || paid === '0'}">Nierozliczone</a>
      </li>
      <li class="mr-1" :class="{'-mb-px': paid === 1 || paid === '1'}">
        <a href="#" @click.prevent="changeTab(1)" class="tab" :class="{'active': paid === 1 || paid === '1'}">Rozliczone</a>
      </li>
    </ul>
    <div class="flex items-center mt-2">
      <div v-show="paid == 0 && employeeId === ''" class="mr-4">
        <!-- Button with addons -->
        <div class="flex flex-none items-center border border-gray-500 shadow rounded-sm" :class="{'bg-yellow-200': search !== ''}">
          <input
            id="filterInput"
            class="appearance-none bg-transparent border-none w-full mr-1 py-1 px-2 leading-tight focus:outline-none"
            v-model="search"
            type="text"
            placeholder="szukaj po nazwisku..."
            @keyup.enter="filterGo"
            @keyup.esc="resetFilters"
          >
          <button
            class="flex-shrink-0 border-transparent border-4 text-gray-600 hover:text-gray-900 text-sm py-1 px-2 rounded"
            type="button"
            :disabled="search === ''" @click="resetFilters"
            title="wyczyść"
          ><font-awesome-icon icon="times"/></button>
          <button
            class="flex-shrink-0 bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600 text-sm border-4 text-white py-1 px-2"
            type="button"
            :disabled="search === ''"
            @click="filterGo"
          >
            Szukaj
          </button>
        </div>
      </div>
      <div
        v-show="paid == 1"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': payment_date_from !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >data wypłaty od:</button>
        <input
          @change="setFilter"
          v-model="payment_date_from"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div
        v-show="paid == 1"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': payment_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >data wypłaty do:</button>
        <input
          @change="setFilter"
          v-model="payment_date_to"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div
        v-show="paid == 0"
        class="relative mr-4 w-auto flex items-stretch border border-gray-500 hover:border-gray-700 shadow rounded-sm"
        :class="{'bg-yellow-200': order_close_date_to !== ''}"
      >
        <button
          class="flex-shrink-0 bg-gray-500 border-gray-500 text-sm border-4 text-white font-semibold py-1 px-2 cursor-default"
          type="button"
        >
          data zamknięcia zlecenia do
        </button>
        <input
          @change="setFilter"
          v-model="order_close_date_to"
          type="date"
          class="appearance-none text-gray-700 bg-transparent px-2 py-1 pr-8 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
    </div>
    <div v-show="employeeId === ''" class="list-group">
    <!-- <div class="block"> -->
      <div class="hidden md:flex w-full py-1 px-5 border-b border-gray-400 font-semibold">
        <div class="w-full sm:w-1/2 lg:w-1/6">
          Pracownik
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
          zleceń
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
          pozycji
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
          kwota
        </div>
      </div>
      <div v-if="isBusy">
        <div class="flex w-full py-3 px-5">
          <div class="flex items-center text-center w-full">
            <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
            Ładuję...
          </div>
        </div>
      </div>
      <template v-else-if="employeeId === ''">
        <div v-for="(employeeSum, index) in employeesSalarySums" :key="index" @click="getEmployeeSalaries(employeeSum.employee_id, employeeSum.name)" class="flex flex-wrap w-full py-3 px-5 border-b border-gray-400 last:border-b-0 cursor-pointer">
          <div class="w-full sm:w-1/2 lg:w-1/6">
            {{ employeeSum.name }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            {{ employeeSum.orders_count }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            {{ employeeSum.salary_count }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right font-mono">
            {{ employeeSum.salary_sum | moneyPL }}
          </div>
        </div>
        <div class="flex flex-wrap w-full py-3 px-5 border-b border-gray-400 last:border-b-0 font-bold">
          <div class="w-full sm:w-1/2 lg:w-1/6 text-right">
            Suma
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right font-mono">
            {{ allOrdersCount }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right">
            {{ allSalariesCount }}
          </div>
          <div class="w-full sm:w-1/2 lg:w-1/12 text-right font-mono">
            {{ allSalariesSum | moneyPL }}
          </div>
        </div>
      </template>
    </div>
    <EmployeeSalaries
      v-if="!isBusy && this.loaded && employeeId !== ''"
      @close="closeEmployeeSalaries"
      :employeeId="employeeId"
      :employeeName="employeeName"
      :paid="paid"
      :order_close_date_to="order_close_date_to"
      :payment_date_from="payment_date_from"
      :payment_date_to="payment_date_to"
    />

  </div>
</template>

<script>
import moment from 'moment';
import { errorHandler } from '../mixins/errorHandler';
import SalaryService from '../services/SalaryService';
import EmployeeSalaries from '../components/EmployeeSalaries.vue';

export default {
  name: 'Salaries',
  components: {
    EmployeeSalaries,
  },
  mixins: [errorHandler],
  data() {
    return {
      isBusy: true,
      loaded: false,
      query: {},
      search: '',
      payment_date_from: '',
      payment_date_to: '',
      order_close_date_to: '',
      paid: 0,
      employeesSalarySums: [],
      employeeId: '',
      employeeName: '',
    };
  },
  computed: {
    allSalariesSum() {
      return this.employeesSalarySums.reduce((prev, next) => ({ salary_sum: Number(prev.salary_sum) + Number(next.salary_sum) }), { salary_sum: 0 }).salary_sum;
    },
    allSalariesCount() {
      return this.employeesSalarySums.reduce((prev, next) => ({ salary_count: Number(prev.salary_count) + Number(next.salary_count) }), { salary_count: 0 }).salary_count;
    },
    allOrdersCount() {
      return this.employeesSalarySums.reduce((prev, next) => ({ orders_count: Number(prev.orders_count) + Number(next.orders_count) }), { orders_count: 0 }).orders_count;
    },
  },
  mounted() {
    this.setQueryFromUri();
  },
  methods: {
    setQueryFromUri() {
      let changed = 0;

      if (this.$route.query.paid === undefined) {
        this.query.paid = 0;
        this.query.order_close_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        this.order_close_date_to = this.query.order_close_date_to;
        changed += 1;
      } else {
        this.query.paid = this.$route.query.paid;
        if (this.$route.query.search !== undefined && this.$route.query.search !== '') {
          this.query.search = this.$route.query.search;
          this.search = decodeURI(this.$route.query.search);
        }
        if (this.query.paid === 1 || this.query.paid === '1') {
          if (this.$route.query.payment_date_to !== undefined) {
            this.query.payment_date_to = this.$route.query.payment_date_to;
          } else {
            this.query.payment_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.payment_date_to = this.query.payment_date_to;
          if (this.$route.query.payment_date_from !== undefined) {
            this.query.payment_date_from = this.$route.query.payment_date_from;
          } else {
            this.query.payment_date_from = moment(this.payment_date_to).startOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.payment_date_from = this.query.payment_date_from;
        } else if (this.query.paid === 0 || this.query.paid === '0') {
          if (this.$route.query.order_close_date_to !== undefined) {
            this.query.order_close_date_to = this.$route.query.order_close_date_to;
          } else {
            this.query.order_close_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            changed += 1;
          }
          this.order_close_date_to = this.query.order_close_date_to;
        }
      }

      this.paid = this.query.paid;

      if (changed > 0) {
        const { name, params } = this.$route;
        this.$router.replace({ name, params, query: this.query });
      } else {
        this.getSalaries();
      }
    },
    getSalaries() {
      this.isBusy = true;
      this.loaded = false;
      const query = {};
      Object.keys(this.query).forEach(key => {
        query[key] = decodeURI(this.$route.query[key]);
      });
      SalaryService.getSalaries(query)
        .then(response => {
          this.employeesSalarySums = response.data.salaries;
          this.isBusy = false;
          this.loaded = true;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    changeTab(paid) {
      if (paid === 1) {
        if (this.payment_date_to === '') {
          this.payment_date_to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        }
        if (this.payment_date_from === '') {
          this.payment_date_from = moment(this.payment_date_to).startOf('month').format('YYYY-MM-DD');
        }
        this.query.payment_date_from = this.payment_date_from;
        this.query.payment_date_to = this.payment_date_to;
      } else {
        this.query.order_close_date_to = this.order_close_date_to;
      }
      this.paid = paid;
    },
    getEmployeeSalaries(employeeId, name) {
      this.employeeId = employeeId;
      this.employeeName = name;
    },
    closeEmployeeSalaries() {
      this.employeeId = '';
      this.employeeName = '';
    },
    resetFilters() {
      this.search = '';
      this.filterGo();
    },
    setFilter() {
      Object.assign(this.query, this.$route.query);
      this.query.paid = this.paid;
      delete this.query.payment_date_from;
      delete this.query.payment_date_to;
      delete this.query.report_date_to;
      if (this.paid === 1 || this.paid === '1') {
        this.query.payment_date_from = this.payment_date_from;
        this.query.payment_date_to = this.payment_date_to;
      } else if (this.paid === 0 || this.paid === '0') {
        this.query.order_close_date_to = this.order_close_date_to;
      }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.query)) {
        this.$router.push({ name: 'salaries', query: this.query });
      }
    },
    filterGo() {
      Object.assign(this.query, this.$route.query);
      delete this.query.search;
      if (this.search !== '') {
        this.query.search = encodeURI(this.search);
      }
      this.$router.push({ name: 'salaries', query: this.query });
    },
  },
  watch: {
    $route(to, from) {
      if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
        if (JSON.stringify(to.query) === '{}') {
          this.setQueryFromUri();
        } else {
          this.getSalaries();
        }
      }
    },
    paid: 'setFilter',
  },
};
</script>

<style scoped lang="scss">
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
  .tab {
    @apply bg-white;
    @apply inline-block;
    @apply h-full;
    @apply py-2;
    @apply px-4;
    @apply font-semibold;
    &.active {
      @apply text-gray-700;
      @apply cursor-default;
      @apply border-l;
      @apply border-t;
      @apply border-r;
      @apply rounded-t;
      &:hover {
        @apply text-gray-700;
        @apply no-underline;
      }
    }
    &:not(.active) {
      @apply cursor-pointer;
      @apply text-blue-500;
      &:hover {
        @apply text-blue-800;
      }
    }
  }
</style>
