<template>
  <div>
    <hr class="my-1">
    <div class="flex items-center mt-2">
      <div class="w-auto mr-2">
        <button @click="$emit('close')" class="py-2 px-3 bg-blue-300 hover:bg-blue-500 text-white rounded-sm" title="powrót do listy pracowników">
          <font-awesome-icon class="mr-2" icon="angle-left" />powrót
        </button>
      </div>
      <div v-if="paid !=1 && !isBusy" class="w-auto mr-2">
        <button @click="updateAsPaidHandler('all')" class="py-2 px-3 bg-blue-400 hover:bg-blue-600 text-blue-100 hover:text-white rounded-sm" title="zaakceptuj wszystkie jako rozliczone">
          <font-awesome-icon size="lg" icon="file-invoice"/><font-awesome-icon size="lg" class="ml-1" icon="caret-right"/> Rozlicz wszystkie
        </button>
      </div>
    </div>
    <div class="flex gap-4 items-center text-lg font-semibold text-gray-700">
      <div>{{employeeName}}</div>
      <div v-if="paid == 0" @click="exportCsv" class="cursor-pointer" title="export do CSV">
        <font-awesome-icon class="text-green-500" :icon="['far', 'file-excel']" />
      </div>
    </div>

    <div class="w-full xl:w-3/4">
      <div v-if="isBusy">
        <div class="flex w-full py-3 px-5">
          <div class="flex items-center text-center w-full">
            <font-awesome-icon icon="spinner" size="2x" spin class="mr-3" />
            Ładuję...
          </div>
        </div>
      </div>
      <template v-else>
        <!-- Podsumowanie -->
        <div class="list-group">
          <div class="flex flex-wrap w-full py-1 px-5 border-gray-400 font-semibold">
            <div class="w-1/2 lg:w-1/3">
              Ilość zleceń: {{ salaryOrders.length }}
            </div>
            <div class="w-1/2 lg:w-1/3 text-right lg:text-left">
              Ilość pozycji: {{ allSalarySum }}
            </div>
            <div class="w-full lg:w-1/3 text-right">
              suma wynagrodzenia: {{allSalaryAmountSum | moneyPL}}
            </div>
          </div>
        </div>
        <div v-for="order in salaryOrders" :key="order.id" class="list-group">
          <div class="flex flex-wrap w-full py-1 px-5 bg-gray-200 border-b border-gray-400 font-semibold">
            <div class="w-1/2 lg:w-1/3 xl:w-1/4">
              <span class="text-gray-500">nr zlecenia:</span>
              <router-link :to="{name: 'order', params: {id: order.id}}" target="_blank">
                {{order.signature}}/{{order.order_date.substring(0, 4)}}
              </router-link>
            </div>
            <div class="w-1/2 lg:w-1/3 xl:w-1/4 text-right lg:text-left">
              <span class="text-gray-500">nr szkody:</span> {{ order.claim_no }}
            </div>
            <div class="w-1/2 lg:w-1/3 xl:w-1/4 text-left lg:text-right xl:text-left">
              <span class="text-gray-500">data zamknięcia:</span> {{ order.close_date.substring(0, 10) }}
            </div>
            <div class="w-1/2 lg:w-full xl:w-1/4 text-right">
              <span class="text-gray-500">suma:</span> {{order.salarySum | moneyPL}}
              <button v-if="paid !=1" @click="updateAsPaidHandler('order', order)" class="ml-2 -mr-4 text-gray-400 hover:text-green-500" title="rozlicz zlecenie">
                <font-awesome-icon size="lg" icon="file-invoice"/><font-awesome-icon size="lg" class="ml-1" icon="caret-right"/>
              </button>
            </div>
          </div>

          <div class="hidden lg:flex flex-wrap w-full py-0 px-5 text-gray-500 border-b border-dashed border-gray-300">
            <div class="w-2/3">
              typ wynagrodzenia <span class="text-sm"><em>(opis dod.)</em></span>
            </div>
            <div class="w-1/6">
              data realizacji
            </div>
            <div class="w-1/6 text-right">
              kwota
            </div>
          </div>
          <div v-for="salary in order.salaries" :key="salary.id" class="flex flex-wrap w-full py-0 px-5">
            <div class="w-full lg:w-2/3">
              {{ salary.salary_type.name }} <span v-if="salary.description" class="text-sm"><em>({{salary.description}})</em></span>
            </div>
            <div class="w-1/2 lg:w-1/6">
              <span v-if="salary.payment_date">{{salary.payment_date}}</span>
              <span v-else class="text-gray-500"><em>oczekuje</em></span>
            </div>
            <div class="w-1/2 lg:w-1/6 text-right font-mono">
              {{ salary.amount | moneyPL }}
              <button v-if="paid !=1" @click="updateAsPaidHandler('salary', salary)" class="text-gray-400 hover:text-green-500 -mr-4" title="rozlicz pozycję">
                <font-awesome-icon icon="file-invoice"/><font-awesome-icon size="lg" class="ml-1" icon="caret-right"/>
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';
import SalaryService from '../services/SalaryService';

export default {
  name: 'EmployeeSalaries',
  mixins: [errorHandler, confirm],
  props: {
    employeeId: {
      required: true,
    },
    employeeName: {
      requirerd: true,
    },
    paid: {
      required: true,
    },
    payment_date_from: {
      required: true,
    },
    payment_date_to: {
      required: true,
    },
    order_close_date_to: {
      required: true,
    },
  },
  data() {
    return {
      isBusy: true,
      salaryOrders: [],
      allSalaryAmountSum: 0,
      allSalarySum: 0,
    };
  },
  mounted() {
    this.getEmployeeSalaries();
  },
  methods: {
    getEmployeeSalaries() {
      const query = {
        paid: this.paid,
      };
      if (this.paid === 1 || this.paid === '1') {
        query.payment_date_from = this.payment_date_from;
        query.payment_date_to = this.payment_date_to;
      } else {
        query.order_close_date_to = this.order_close_date_to;
      }
      SalaryService.getEmployeeSalaries(this.employeeId, query)
        .then(response => {
          this.salaryOrders = response.data.salaries;
          this.allSalaryAmountSum = 0;
          this.allSalarySum = 0;
          this.salaryOrders.forEach(obj => {
            let orderAmountSum = 0;
            obj.salaries.forEach(sal => {
              orderAmountSum += Number(sal.amount);
              this.allSalarySum += 1;
            });
            // eslint-disable-next-line no-param-reassign
            obj.salarySum = orderAmountSum;
            this.allSalaryAmountSum += orderAmountSum;
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    exportCsv() {
      const query = {
        paid: 0,
        order_close_date_to: this.order_close_date_to,
        export_csv: 1,
      };
      SalaryService.getEmployeeSalaries(this.employeeId, query)
        .then(response => {
          const newWindow = window.open(null, 'downloadCSV');
          newWindow.location.href = response.data.url;
        })
        .catch(error => this.resolveError(error));
    },
    updateAsPaidHandler(type, obj = {}) {
      let message = '';
      const ids = [];
      if (type === 'salary') {
        message = `Potwierdź rozliczenie pozycji wypłaty: ${obj.amount.replace('.', ',')} zł.`;
        ids.push(obj.id);
      }
      if (type === 'order') {
        const order = this.salaryOrders.find(ord => ord.id === obj.id);
        let messAmounts = '';
        let index = 1;
        order.salaries.forEach(sal => {
          ids.push(sal.id);
          const plAmount = `${sal.amount.replace('.', ',')}zł`;
          messAmounts += index > 1 ? ` | ${plAmount}` : plAmount;
          index += 1;
        });
        message = `Potwierdź rozliczenie pozycji wypłat zlecenia ${order.signature}/${order.order_date.substring(0, 4)}: ${messAmounts}.`;
      }
      if (type === 'all') {
        this.salaryOrders.forEach(ord => {
          ord.salaries.forEach(sal => ids.push(sal.id));
        });
        message = 'Potwerdź rozliczenie wszystkich wypłat widocznych w raporcie.';
      }
      this.confirm(() => this.updateAsPaid(ids), message);
    },
    updateAsPaid(ids) {
      this.isBusy = true;
      SalaryService.updateAsPaid({ salary_ids: ids })
        .then(() => {
          this.getEmployeeSalaries();
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
  },
  watch: {
    paid: 'getEmployeeSalaries',
    payment_date_from: 'getEmployeeSalaries',
    payment_date_to: 'getEmployeeSalaries',
    order_close_date_to: 'getEmployeeSalaries',
  },
};
</script>

<style lang="scss" scoped>
  .list-group {
    @apply mt-2;
    @apply border;
    @apply rounded-sm;
    @apply border-gray-400;
  }
</style>
